import React, { useContext, useRef, useState } from 'react';
import { Spotlight } from './ui/Spotlight';
import { TextGenerateEffect } from './ui/TextGenerate';
import MagicButton from './ui/MagicButton';
import { motion } from 'framer-motion';
import { BackgroundCircles } from './design/BackgroundCircles';
import { ScrollParallax } from 'react-just-parallax';
import { useGetMeQuery } from '../../generated/graphql';
import { useNavigate } from 'react-router-dom';
import RegisterSa from './saRegister/saRegister';
import authContext from '../../contexts/authContext';
import AuthContext from '../../contexts/authContext';

interface HeroProps {
  handleRegister: () => void;
}

const sliderVariants = {
  initial: {
    x: 0,
  },
  animate: {
    x: "-240%",
    transition: {
      repeat: Infinity,
      repeatType: "mirror" as const,
      duration: 20,
    },
  },
};

const Hero: React.FC<HeroProps> = ({ handleRegister }) => {
  const ParallaxRef = useRef(null);
  const { data, loading, error } = useGetMeQuery();
  const authContext = useContext(AuthContext);

  // const { state } = authContext;

  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

  

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };
  

  return (
    <motion.div className='pb-20 pt-36 w-full h-[100vh]' ref={ParallaxRef}>
      <div className='w-full'>
        <Spotlight className='-top-40 -left-10 md:-left-32 md:-top-20 h-screen ' fill-white />
        <Spotlight className='top-10 left-full h-[80vh] w-[100vw] ' fill="purple" />
        <Spotlight className='top-10 left-full h-[80vh] w-[100vw] ' fill="blue" />
      </div>
      <div className='flex justify-center relative my-20 z-20'>
        <div className='max-w-[89vw] md:max-w-2xl lg:max-w-[60vw] flex flex-col items-center justify-center '>
          <h2 className='uppercase tracking-widest text-xs text-center text-blue-100 max-w-90'>
            saarang - Annual cultural fest of IIT Madras
          </h2>
          <TextGenerateEffect className='text-center text-[60px] md:text-5xl lg:text-9xl '
            words='Join our Saarang Student Ambassador Program'
          />
          <p className='text-center md:tracking-wider mb-4 text-sm md:text-lg lg:text-xl text-blue-100'>
            Earn Rewards, Get Merchandise, Gain Experience, Build Networks, Boost Skills.
          </p>
          {!data?.getMe.sa_answer &&
          <div onClick={handleRegister} className={`z-20 ${data?.getMe.sa_answer ? "hide" : ""}`}>
            <MagicButton
              title={data?.getMe?.positions === "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001" ?`Register for SA Program`:`Register`}
              icon=''
              position=''
              handleClick={handleRegister}
              />
          </div>
}
          <motion.div className='uppercase absolute text-[#ffffff19] pointer-events-none whitespace-nowrap lg:-bottom-72 w-[100%] font-bold max-sm:text-2xl bottom-10' variants={sliderVariants}
            initial="initial"
            animate="animate"
            style={{ fontSize: '50vh' }}>
            Saarang IIT Madras
          </motion.div>
          {/* <motion.div className='uppercase absolute text-[#ffffff19] pointer-events-none whitespace-nowrap lg:-bottom-72 w-[100%] font-bold md:hidden -bottom-44 block' variants={sliderVariants}
            initial="initial"
            animate="animate"
            style={{ fontSize: '20vh' }}>
            Saarang IITM annual fest
          </motion.div> */}
          <div className='absolute top-0 z-0'>
            <BackgroundCircles parallaxRef={ParallaxRef} />
          </div>
        </div>
      </div>

      {isPopupVisible && <RegisterSa onClose={handleClosePopup} />}
    </motion.div>
  );
}

export default Hero;