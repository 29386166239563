import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Ambassador from "./pages/sa/ambassador/ambassador";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WhyCa from "./combonents/sa/whyCa/whyCa";
import AuthPage from "./pages/auth/auth";
import ForgotPassword from "./pages/auth/forgotpassword/forgot";
import ResetPassword from "./pages/auth/forgotpassword/resetpassword";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Ambassador />} />

          
          <Route path="/forgotpassword"  element={<ForgotPassword/>}/>
          <Route path="/resetpassword/:id1/:id2" element={<ResetPassword/>}/>
          <Route path="auth" element={<AuthPage/>}/>
       
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
