// saRegister.tsx
import React, { useEffect, useState } from "react";
import { useRegisterSaMutation } from "../../../generated/graphql";
import "./saRegister.scss";
import Popup from "../../../ui-elements/popup/popup";

interface RegisterSaProps {
  onClose: () => void;
}

const RegisterSa: React.FC<RegisterSaProps> = ({ onClose }) => {
  const [saAnswer, setSaAnswer] = useState<string>("");
  const [saReferral, setSaReferral] = useState<string>("");
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [saAnswer2, setSaAnswer2]=useState<string>("");
  const [saAnswer3, setSaAnswer3]=useState<string>("");
  const [isRegisterPopupVisible, setIsRegisterPopupVisible] = useState<boolean>(false);

  const [registerSa, { data, loading, error }] = useRegisterSaMutation();

  const handleRegisterSa = async () => {
    try {
      await registerSa({
        variables: {
          saAnswer,
          saAnswer2,
          saAnswer3,
          saReferral,
        
        },
      });

      if (data) {
        // console.log("User updated:", data.registerSa);
      }
    } catch (err) {
      // console.error("Error updating user:", err);
    }
  };
  useEffect(() => {
    if (data?.registerSa) {
      setIsPopupVisible(true);
      setTimeout(() => {
        setIsPopupVisible(false);
        window.location.reload();
      }, 2000); // Display the popup for 2 seconds
    }
  }, [data]);

  return (
    <div className="saregister-box absolute left-0 flex justify-center items-center  h-full z-40 -top-20 w-full">
      <div className="bg-[#000319] flex flex-col justify-center items-center opacity-100 md:p-12 p-6 rounded-lg shadow-gray-500 shadow-2xl outline-2 outline-white relative">
        <button
          onClick={onClose}
          className="absolute top-6 right-6 text-white "
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h2 className="font-extralight text-white text-4xl pb-8">Register for SA Program</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleRegisterSa();
          }}
        >
          <div className="w-[300px] lg:w-[5
          00px] py-2 border-gray-100 border-[1px] rounded-lg my-4">
            <input
              id="saAnswer"
              className="w-full outline-none bg-black px-4 text-white"
              type="text"
              value={saReferral}
              placeholder="Referral ID (If any)"
              onChange={(e) => setSaReferral(e.target.value)}
              
            />
          </div>
          <div className="w-[300px] lg:w-[500px] py-2 border-gray-100 border-[1px] rounded-lg my-4 flex">
            <textarea
              id="saReferral"
              className="w-full outline-none bg-black px-4 text-white resize-none overflow-hidden"
              placeholder="Why do you want to become SA"
              value={saAnswer}
              onChange={(e) => setSaAnswer(e.target.value)}
              rows={1}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = 'auto';
                // target.style.height = `${target.scrollHeight}px`;
              }}
              required
            />
          </div>
          <div className="w-[300px] lg:w-[500px] py-2 border-gray-100 border-[1px] rounded-lg my-4 flex">
            <textarea
              id="saReferral"
              className="w-full outline-none bg-black px-4 text-white resize-none overflow-hidden text-sm"
              placeholder="How do you plan to collaborate with other student organizations or clubs in your college to enhance the reach of our fest?"
              value={saAnswer2}
              onChange={(e) => setSaAnswer2(e.target.value)}
              rows={3}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = 'auto';
                // target.style.height = `${target.scrollHeight}px`;
              }}
              required
            />
          </div>
          <div className="w-[300px] lg:w-[500px] py-2 border-gray-100 border-[1px] rounded-lg my-4 flex">
            <textarea
              id="saReferral"
              className="w-full outline-none bg-black px-4 text-white resize-none overflow-hidden text-sm"
              placeholder="Can you share an example of a successful event you promoted and how you maximized student engagement?"
              value={saAnswer3}
              onChange={(e) => setSaAnswer3(e.target.value)}
              rows={3}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = 'auto';
                // target.style.height = `${target.scrollHeight}px`;
              }}
              required
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="bg-white p-3 rounded-lg font-bold text-xl tracking-wide mt-4"
          >
            {loading ? "Registering..." : "Register SA"}
          </button>
        </form>
         {isPopupVisible?<Popup message="Welcome to the Saarang Student Ambassador Program!"/>:""}
      </div>
    </div>
  );
};

export default RegisterSa;