import React, {useState} from "react";

interface PortalContextIntf {
  receivedPortal: string;
  changeRecPortal : (name: string) => void;
}

const PortalContext = React.createContext<PortalContextIntf | null>(null);

function PortalContextProvider(props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) {

  const portals = {
    "sales" : "https://sales.saarang.org",
    "ambassadors" : "http://localhost:3000",
    "saarang-main":"http://localhost:3001",
    "accomodation":"http://localhost:3002"
  }

  const [receivedPortal, setReceivedPortal] = useState<string>("");

  const changeRecPortal = (name: string) => {
    setReceivedPortal(name);
  }

  return (
    <PortalContext.Provider value={{receivedPortal, changeRecPortal}}>
      {props.children}
    </PortalContext.Provider>
  );
}

export default PortalContext;
export { PortalContextProvider };
