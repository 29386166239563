import { useSelector } from "react-redux";
import { RootState } from "../../redux/device/reducers";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import PortalContext from "../../contexts/portalContext";
import AuthContext from "../../contexts/authContext";
import {
  useCommonRegisterMutation,
  useLoginMutation,
} from "../../generated/graphql";
import "./auth.scss";
import SlidingImage1 from "../../Images/Sliding-Image-1.jpg";
import SlidingImage2 from "../../Images/Sliding-Image-2.jpg";
import SlidingImage3 from "../../Images/Sliding-Image-3.jpg";
import SlidingImage4 from "../../Images/Sliding-Image-4.jpg";
import SlidingImage5 from "../../Images/Sliding-Image-5.jpg";
import SlidingImage6 from "../../Images/Sliding-Image-6.jpg";
import SlidingImage7 from "../../Images/Sliding-Image-7.jpg";
import useWindowSize from "../../redux/device/useWindowSize";
import ValidateEmail from "../../regexexpressions/emailValidation";
import ValidateMobile from "../../regexexpressions/mobileValidation";
import Popup from "../../ui-elements/popup/popup";
const AuthPage: React.FC = () => {
  useWindowSize();

  const { device } = useSelector((state: RootState) => state.windowSize);
  const portalContext = useContext(PortalContext);

  const { portal } = useParams<{ portal: string }>();
  if (portal) {
    portalContext?.changeRecPortal(portal);
  }

  const [user, setUser] = React.useState({
    emailPrimary: "", // value for 'emailPrimary'
    username: "", // value for 'username'
    password: "", // value for 'password'
    portal: portal,
    phoneNum: "",
    college: "", // value for 'college'
    yearOfStudy: "", // value for 'yearOfStudy'
    region: "",
    state: "",
    isIITM: "No",
    // value for 'region'
  });
  const [loginUser, setLoginUser] = React.useState({
    email: "",
    password: "",
  });
  const authContext = React.useContext(AuthContext);

  const Images = [
    SlidingImage1,
    SlidingImage2,
    SlidingImage3,
    SlidingImage4,
    SlidingImage5,
    SlidingImage6,
    SlidingImage7,
  ];
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");

  const [regError, setRegError] = useState("");
  const [logError, setLogError] = useState("");
  const [activeForm, setActiveForm] = useState("login");
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("hide");
  const [option, setOption] = useState("Yes");

  const [isRegisterPopupVisible, setIsRegisterPopupVisible] = useState<boolean>(false);
  const [isLoginPopupVisible, setIsLoginPopupVisible] = useState<boolean>(false);



  // const [registerMutation, { data, loading, error }] = useRegisterMutation({
  //   variables: {
  //   emailPrimary: user.email,// value for 'emailPrimary'
  //   username: user.name,// value for 'username'
  //   password: user.password,// value for 'password'
  //   },
  // });
  const [
    loginMutation,
    { data: loginData, loading: logonLoadinf, error: loginError },
  ] = useLoginMutation({});

  const [commonRegisterMutation, { data, loading, error }] =
    useCommonRegisterMutation({
      variables: {
        emailPrimary: user.emailPrimary, // value for 'emailPrimary'
        username: user.username, // value for 'username // value for 'dob'
        password: user.password, // value for 'password'
        portal: user.portal ? user.portal : "", // value for 'portal',
        mobileNumberPrimary: user.phoneNum,
        college: user.college, // value for 'college'
        yearOfStudy: user.yearOfStudy, // value for 'yearOfStudy'
        region: user.region,
        state: user.state,
        isIitm: user.isIITM,
        positions:
          "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001",
        // value for 'region'1
      },
    });

  useEffect(() => {
    if (data) {
      // console.log(data);
      // console.log("Reg user done");
      // window.location.reload();
      // authContext?.signUp(data?.register);
      // console.log("CHECK EMAIL");
      if (data?.commonRegister) {
        setIsRegisterPopupVisible(true);
        setTimeout(() => {
          setIsRegisterPopupVisible(false);
          window.location.reload();
        }, 2000); // Display the popup for 2 seconds
      }
    }
  }, [data]);

  useEffect(() => {
    if (loginData) {
      // console.log(data);
      // console.log("Reg user done");
      // window.location.reload();
      // authContext?.signUp(data?.register);
      // console.log("CHECK EMAIL");
      
      window.location.href = "/";
    }
  }, [loginData]);

  useEffect(() => {
    //If registration error
    if (error) {
      if (error.message === "Error: email already exists") {
        setRegError(
          "You have already registered in one of the Saarang Portals. Please login to continue"
        );
      }
    } else {
      setRegError("");
    }
  }, [error]);

  const registerSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!ValidateEmail(user.emailPrimary)) {
      setError1("Please Enter a valid email");
      return;
    }
    if (!ValidateMobile(user.phoneNum)) {
      setError1("Please enter a valid phone number");
      return;
    }
    if (user.password !== passwordConfirm) {
      setError1("Passwords do not match");
      return;
    } 

    if (
      user.emailPrimary === "" ||
      user.password === "" ||
      user.username === "" ||
      user.college === "" ||
      user.phoneNum === "" ||
      user.region === "" ||
      user.yearOfStudy === "" ||
      user.state === "" ||
      user.isIITM===""
    ) {
      setError1("Please fill all the fields");
      return;
    } else {
      try {
        await commonRegisterMutation();
      } catch (err: any) {
        setError1(err.message);
      }
    }
  };
  const loginSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (loginUser.email === "" || loginUser.password === "") {
      setError2("Please fill all the fields");
      return;
    }

    if (!ValidateEmail(loginUser.email)) {
      setError2("Please Enter a valid email");
      return;
    }

    try {
      const { data } = await loginMutation({
        variables: {
          email: loginUser.email,
          password: loginUser.password,
        },
      });

      // Handle success, if needed
    } catch (err: any) {
      console.error(err.message);

      if (err.message === "Account Not Found") {
        setError2("Account Not Found");
      } else if (err.message === "Invalid Credential") {
        setError2("Invalid Credential");
      } else {
        setError2(err.message);
      }
    }
  };

  const [currentIndex, setCurrentIndex] = useState<number>(
    Math.floor(Math.random() * 7)
  );
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="signup_box">
       {isRegisterPopupVisible && <Popup message="Registration Successful!" />} 
      <div className={`registration-form-and-image ${activeForm} ${device}`}>
        <div className={`sliding-image ${activeForm} ${device} `}>
          {Images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className={`image-slider__image ${
                index === currentIndex ? "active" : ""
              }`}
            />
          ))}
        </div>
        <div
          className={`registration-form  ${
            activeForm === "register" ? "active" : ""
          }`}
        >
          <form onSubmit={registerSubmitHandler}>
            <div className={`registration-box ${device}`}>
              <div className={`heading text-white ${device}`}>REGISTER</div>
              <div className={`text-white sub-heading ${device}`}>
                Create your Saarang Account!!!
              </div>

              <div className={`inputbox-page ${first} fields`}>
                <input
                  type="text"
                  className="form-styling"
                  id="name"
                  placeholder="Enter name*"
                  value={user.username}
                  onChange={(e) => {
                    setUser({ ...user, username: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />

                <input
                  type="text"
                  className="form-styling"
                  id="email-reg"
                  placeholder="Enter email*"
                  value={user.emailPrimary}
                  onChange={(e) => {
                    setUser({ ...user, emailPrimary: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />
                <br></br>
                <input
                  type="text"
                  className="form-styling"
                  id="phone_number"
                  placeholder="Phone number*"
                  value={user.phoneNum}
                  onChange={(e) => {
                    setUser({ ...user, phoneNum: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />
                <br></br>
                <input
                  type="password"
                  className="form-styling"
                  id="password-reg"
                  placeholder="Enter password*"
                  value={user.password}
                  onChange={(e) => {
                    setUser({ ...user, password: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />
                <br></br>
                <input
                  type="password"
                  className="form-styling"
                  id="passwordConfirm"
                  placeholder="Confirm password*"
                  value={passwordConfirm}
                  onChange={(e) => {
                    setPasswordConfirm(e.target.value);
                    setError1("");
                    setRegError("");
                  }}
                />
                <div className="width100">
                  <div
                    className={`text-white dont-have-account ${device} login-here`}
                    onClick={() => {
                      setActiveForm("login");
                    }}
                  >
                    Already have an account? Login here
                  </div>
                  <button
                    type="button"
                    className="button-style"
                    onClick={() => {
                      setFirst("hide");
                      setSecond("");
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>

              <div className={`inputbox-page ${second}`}>
                {/* {portal === "ambassadors" ? */}

                <input
                  type="text"
                  className="form-styling"
                  id="college"
                  placeholder="College*"
                  value={user.college}
                  onChange={(e) => {
                    setUser({ ...user, college: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />

                <input
                  type="text"
                  className="form-styling"
                  id="year_of_study"
                  placeholder="Year of Study*"
                  value={user.yearOfStudy}
                  onChange={(e) => {
                    setUser({ ...user, yearOfStudy: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />

                <input
                  type="text"
                  className="form-styling"
                  id="region"
                  placeholder="City*"
                  value={user.region}
                  onChange={(e) => {
                    setUser({ ...user, region: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />

                <input
                  type="text"
                  className="form-styling"
                  id="state"
                  placeholder="State*"
                  value={user.state}
                  onChange={(e) => {
                    setUser({ ...user, state: e.target.value });
                    setError1("");
                    setRegError("");
                  }}
                />

                {/* <div className="form-styling yes-no-question"> */}
                <div className="form-styling yes-no-question">
                  <label htmlFor="iitm">IITM BS student?</label>
                  <select
                    className="selected-option"
                    id="iitm"
                    name="iitm"
                    value={user.isIITM}
                    onChange={(e) => {
                      setUser({ ...user, isIITM: e.target.value });
                    }}
                  >
                    <option className="option" value="No">
                      No
                    </option>
                    <option className="option" value="Yes">
                      Yes
                    </option>
                  </select>
                </div>
                <div
                  className={`text-white dont-have-account ${device} login-here`}
                  onClick={() => {
                    setActiveForm("login");
                  }}
                >
                  Already have an account? Login here
                </div>

                <div className={`Prev-and-Register ${device}`}>
                  <button
                    type="button"
                    className="button-style prev-button"
                    onClick={() => {
                      setFirst("");
                      setSecond("hide");
                    }}
                  >
                    Prev
                  </button>
                  <button
                    type="submit"
                    className="button-style register-button"
                    onClick={() => registerSubmitHandler}
                  >
                    Register
                  </button>
                </div>
              </div>
              <br />
              {regError !== "" ? (
                <p className="text-danger redir_log">{regError}</p>
              ) : null}
              {error1 !== "" ? (
                <p className="text-danger redir_log">{error1}</p>
              ) : null}
            </div>
          </form>
        </div>

        <div
          className={`registration-form ${
            activeForm === "login" ? "active" : ""
          }`}
        >
          <form onSubmit={loginSubmitHandler}>
            <div className={`registration-box ${device}`}>
              <h3 className={`login-heading text-white  ${device}`}>LOGIN</h3>
              <div className={`text-white login-subheading ${device}`}>
                Login to your Saarang Account!!!
              </div>
              <br />
              <div className="login-input">
                <input
                  type="text"
                  className="form-styling"
                  id="email"
                  placeholder="Enter email*"
                  value={loginUser.email}
                  onChange={(e) => {
                    setLoginUser({ ...loginUser, email: e.target.value });
                    setError2("");
                    setLogError("");
                  }}
                />

                <input
                  type="password"
                  className="form-styling"
                  id="password"
                  placeholder="Enter password*"
                  value={loginUser.password}
                  onChange={(e) => {
                    setLoginUser({ ...loginUser, password: e.target.value });
                    setError2("");
                    setLogError("");
                  }}
                />
                {/* <div className="reg-flex"> */}

                <div
                  className={`text-white dont-have-account ${device}`}
                  onClick={() => {
                    setActiveForm("register");
                    console.log(activeForm);
                  }}
                  >
                  Don't have an account? Register here
                </div>
                {/* <div className="text-white dont-have-account">Forgot password</div> */}
                  {/* </div> */}
                <div className="width100">
                  {loading ? (
                    <p className="loader" role="status"></p>
                  ) : (
                    <button type="submit" className="button-style">
                      Login
                    </button>
                  )}
                </div>
              </div>

              <div className="" style={{ paddingBottom: "10px" }}></div>
              {/* <div className={` text-white dont-have-account ${device}`}>
                 <a href="/forgotpassword" className="text-white" >
                  Forgot Password
                </a> */}
              {error2 !== "" ? (
                <span className="redir_reg text-danger">{error2}</span>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AuthPage;
