import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import PortalContext from "../../../contexts/portalContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/device/reducers";
import SlidingImage1 from "../../../Images/Sliding-Image-1.jpg";
import SlidingImage2 from "../../../Images/Sliding-Image-2.jpg";
import SlidingImage3 from "../../../Images/Sliding-Image-3.jpg";
import SlidingImage4 from "../../../Images/Sliding-Image-4.jpg";
import SlidingImage5 from "../../../Images/Sliding-Image-5.jpg";
import SlidingImage6 from "../../../Images/Sliding-Image-6.jpg";
import SlidingImage7 from "../../../Images/Sliding-Image-7.jpg";
import "./forgot.scss";
import useWindowSize from "../../../redux/device/useWindowSize";

// import AuthContext from "../../contexts/AuthContext";

import { useCheck_ForgotMutation } from "../../../generated/graphql";
import Popup from "../../../ui-elements/popup/popup";

const ResetPassword: React.FC = () => {
  // const authContext = React.useContext(AuthContext);
  useWindowSize();
  const portalContext = useContext(PortalContext);
  const { portal } = useParams<{ portal: string }>();
  if (portal) {
    portalContext?.changeRecPortal(portal);
  }
  const { device } = useSelector((state: RootState) => state.windowSize);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");

  const { id1, id2 } = useParams<{ id1: string; id2: string }>();
  const Images = [
    SlidingImage1,
    SlidingImage2,
    SlidingImage3,
    SlidingImage4,
    SlidingImage5,
    SlidingImage6,
    SlidingImage7,
  ];
  const [currentIndex, setCurrentIndex] = useState<number>(
    Math.floor(Math.random() * 7)
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      alert("Passwords do not match");
      return;
    }

    if (!id1 || !id2) {
      alert("Invalid request");
      return;
    }

    checkForgotMutation({
      variables: {
        email: id1,
        pass: password,
        token: id2,
      },
    });
  };

  const [checkForgotMutation, { data, loading, error }] =
    useCheck_ForgotMutation({});

  useEffect(() => {
    if (data) {
      if (data.check_forgot === true) {
        setTimeout(() => {
          window.location.href = "http://localhost:3000/";
        }, 1000);
      }
    }
  }, [data]);

  return (
    <div className="signup_box">
      {error && <Popup message={error.message.split(":")[1]} />}
      {loading && <Popup message="loading.." />}
      {data && data.check_forgot ? (
        <Popup message="Password Changed Successfully!" />
      ) : null}
      {data && !data.check_forgot ? (
        <Popup message="Password Reset Failed. Try Again." />
      ) : null}

      <div className={`registration-form-and-image ${device}`}>
        <div className={`sliding-image login ${device}`}>
          {Images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className={`image-slider__image ${
                index === currentIndex ? "active" : ""
              }`}
            />
          ))}
        </div>
        <div className={`registration-form active ${device}`}>
          <div className={`forgot-box ${device}`}>
            <div className={`heading text-white ${device} `}>
              Reset Password
            </div>
            <div className="width100 ">
              <br />
              <input
                type="email"
                className={`form-styling ${device}`}
                id="email"
                //   placeholder={id1}
                placeholder="Enter your Email"
                value={id1}
                readOnly
              />
              <br></br>
              <input
                type="password"
                className={`form-styling ${device}`}
                id="password"
                placeholder="Enter new password*"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
              />
              <br></br>
              <input
                type="password"
                className={`form-styling ${device}`}
                id="passwordConfirm"
                placeholder="Confirm new password*"
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                }}
                required
              />
            </div>
            <div className="width100">
              <button
                type="submit"
                className={`button-style ${device}`}
                onClick={submitHandler}
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
