import React from "react";

const Footer = () => {
  return (
    <div className="flex items-center justify-between bg-[#070e41] w-full pt-8 flex-col ">
      <div className="flex lg:flex-row flex-col items-end lg:justify-between w-full lg:px-2 justify-center opacity-80">
        <div className="flex items-center justify-center text-white lg:text-3xl lg:space-x-8 space-x-6 lg:px-4 w-full">
          <a href="https://www.instagram.com/saarang_iitmadras/?hl=en">
            <img src="instagram.svg" alt="Twitter Logo" className="lg:w-8" />
          </a>
          <a href="https://x.com/i/flow/login?redirect_after_login=%2Fsaarang_iitm">
            <img src="twitter.svg" alt="Twitter Logo" className="lg:w-8" />
          </a>
          <a href="https://www.youtube.com/@iitmsaarang">
            <img src="Youtube.svg" alt="Twitter Logo" className="lg:w-8" />
          </a>
          <a href="https://www.linkedin.com/company/saarang/?originalSubdomain=in">
            <img src="linkdin.svg" alt="Twitter Logo" className="lg:w-5 w-4" />
          </a>
        </div>
        <div className="flex flex-col items-center  text-white lg:text-3xl justify-center text-xl max-md:pt-12 w-full opacity-80">
          <h1 className="flex lg:text-start max-md:w-full font-semibold max-md:justify-center">
            Contact us
          </h1>
          <div className="flex lg:justify-start justify-center flex-col text-white">
            <div className="flex flex-row items-end">
              <img src="mail.svg" alt="" className="w-6 " />
              <p className="lg:text-xl font-light px-2 tracking-wide flex items-end">
                studentrelations@saarang.org
              </p>
            </div>
            <div className="flex flex-row py-4 justify-center ">
              <img
                src="contact.svg"
                alt=""
                className="w-6 flex items-start h-full pt-1"
              />
              <p className="lg:text-xl font-light px-2 tracking-wide flex items-end flex-col text-left w-full">
                <span className="text-left w-full">GiriRaghav:9940544153,</span>
                <span className="text-left w-full">Suprasidh:9353662554,</span>
                <span className="text-left w-full">Naveen:9150857069</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full pt-16 pb-3 text-white opacity-50 text-xl">
        Designed and Developed by Saarang DevOps
      </div>
    </div>
  );
};

export default Footer;
