import { motion, useTransform, useScroll, cubicBezier } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import "./whyCa.scss";

// Typing effect utility
const useTypingEffect = (text: string, speed: number) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      setDisplayText(text.slice(0, index + 1));
      index++;
      if (index >= text.length) clearInterval(timer);
    }, speed);

    return () => clearInterval(timer);
  }, [text, speed]);

  return displayText;
};

const WhyCa = () => {
  return (
      
      <HorizontalScrollCarousel />
      
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const easing = cubicBezier(0.17, 0.67, 0.83, 0.67);
  const x = useTransform(scrollYProgress, [0, 1], ["200vw", "-200vw"], {
    ease: easing,
  });

  // Use typing effect for text
  const [card2_text, set_card2_text] = useState(
    "CONNECT WITH DRIVEN PEERS NATIONWIDE"
  );
  const [card1_text, set_card1_text] = useState(
    "EARN EXCITING COUPONS AND VOUCHERS UPON TASK COMPLETION."
  );
  const card2_display_text = useTypingEffect(card2_text, 15);
  const card1_display_text = useTypingEffect(card1_text, 15);
  const [card3_text, set_card3_text] = useState(
    "ALL SAS RECEIVE CERTIFICATES; TOP PERF"
  );
  const card3_display_text = useTypingEffect(card3_text, 15);

  const [card4_text, set_card4_text] = useState(
    "GAIN EXPERTISE IN TEAM MANAGEMENT, EVENT ORGANIZATION, PUBLIC SPEAKING"
  );
  const card4_display_text = useTypingEffect(card4_text, 15);

  const [card5_text, set_card5_text] = useState("RECEIVE PREMIUM MERCHANDISE");
  const card5_display_text = useTypingEffect(card5_text, 15);

  return (
    <section
      ref={targetRef}
      className="whyCa-container relative h-[300vh] bg-darkBlue-1 oswald-1 "
    >
      <div className="sticky top-0 flex h-screen items-center overflow-hidden">
        <motion.div
          className="progress-bar"
          style={{ scaleX: scrollYProgress }}
        />
        <motion.div
          style={{ x }}
          transition={{ duration: 10 }}
          className="flex flex-row"
        >
          <div className="card-1 card flex flex-col md:flex-row  common-card-component ">
            <div className="card-content">
              <div className="card-images flex ml-16 relative">
              
                <img
                  src="/whyCa/card1/img1.png"
                  alt="Ticket 1"
                  className="ticketImage"
                />
              <div className="absolute text-white top-20 left-40" style={{fontSize:"3rem"}}>Vouchers</div>
              </div>
            </div>
            <motion.div
              className="card-text h-full common-text-component"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
              key={card1_text}
            >
              {card1_display_text}
            </motion.div>
          </div>
          <div
            className="card card-2 flex flex-col md:flex-row"
            onMouseEnter={() =>
              set_card2_text(
                "CONNECT WITH DRIVEN PEERS NATIONWIDE, FORGING A NETWORK OF LIKE-MINDED STUDENTS."
              )
            }
            onMouseLeave={() =>
              set_card2_text("CONNECT WITH DRIVEN PEERS NATIONWIDE")
            }
          >
            <div className="card-content relative">
              <div className="card-images flex ml-36">
                <div className="glow2"></div>
                <img
                  src="/whyCa/card2/img.png"
                  alt="Ticket 5"
                  className="ticket-image2 w-1/2"
                />
              </div>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
                {/* <h2 className="hello text-2xl font-bold text-white">
                  text
                </h2> */}
              </div>
            </div>
            <motion.div
              className="text2 h-full common-text-component"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
              key={card2_text}
            >
              {card2_display_text}
            </motion.div>
          </div>
          <div
            className="card card-3 flex flex-col md:flex-row"
            onMouseEnter={() =>
              set_card3_text(
                "ALL SAS RECEIVE CERTIFICATES; TOP PERFORMERS EARN EXCELLENCE AWARDS."
              )
            }
            onMouseLeave={() =>
              set_card3_text("ALL SAS RECEIVE CERTIFICATES; TOP PERF")
            }
          >
           <div className="card-content">
              <div className="card-images flex ml-36 ">
                <div className="glow3"></div>
                <div className="relative">
                  <img
                    src="/whyCa/card3/img.png"
                    alt="Ticket 5"
                    className="ticket-image3"
                  />
                  {/* <div className="centered-text absolute top-[11vh] left-20 text-[1.2rem] text-white">
                   {" STUDENT \n AMBASSADOR"}
                  </div> */}
                </div>
              </div>
            </div>
            <motion.div
              className="text3 h-full common-text-component"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
              key={card3_text}
            >
              {card3_display_text}
            </motion.div>
          </div>
          <div
            className="card card-4 flex flex-col md:flex-row"
            onMouseEnter={() =>
              set_card4_text(
                "GAIN EXPERTISE IN MANAGEMENT, EVENTS, PUBLIC SPEAKING, SOCIAL MEDIA, WRITING, AND TECH SKILLS."
              )
            }
            onMouseLeave={() =>
              set_card4_text(
                "GAIN EXPERTISE IN TEAM MANAGEMENT, EVENT ORGANIZATION, PUBLIC SPEAKING"
              )
            }
          >
            <div className="card-content relative">
              <div className="redBorder absolute w-3 h-[400px] bg-red-400"></div>
              <div className="card-images4 flex ml-36 ">
                <div className="glow4"></div>

                <img
                  src="/whyCa/card4/img.png"
                  alt="Ticket 5"
                  className="ticket-image4"
                />
              </div>
            </div>
            <motion.div
              className="text4 h-full common-text-component"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
              key={card4_text}
            >
              {card4_display_text}
            </motion.div>
          </div>
          <div
            className="card card-5 flex flex-col md:flex-row"
            onMouseEnter={() =>
              set_card5_text("RECEIVE PREMIUM MERCHANDISE AND GOODIES")
            }
            onMouseLeave={() => set_card5_text("RECEIVE PREMIUM MERCHANDISE")}
          >
            <div className="card-content relative">
              <div className="card-images5 flex ml-36 ">
                <div className="glow5"></div>

                <img
                  src="/whyCa/card5/T1.png"
                  alt="Ticket 5"
                  className="ticket-image5_1"
                />
                <img
                  src="/whyCa/card5/T2.png"
                  alt="Ticket 5"
                  className="ticket-image5_2"
                />
              </div>
            </div>
            <motion.div
              className="text5 h-full common-text-component"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
              key={card5_text}
            >
              {card5_display_text}
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default WhyCa;
