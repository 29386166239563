import { useSelector } from "react-redux";
import { RootState } from "../../../redux/device/reducers";
import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";


const data = {
    title: <h1 className="font-bold text-center text-[40px] md:text-5xl lg:text-6xl">FAQs</h1>,
    rows: [
        {
            title: "1. What is the SA program / who is a Student Ambassador? ",

            content: `The Student Ambassador represents Saarang, IIT Madras' cultural fest, on their campus and helps publicize events and workshops. This role is akin to a digital marketing internship.
`,
        },
        {
            title: "2. How / where should I apply? ",

            content:
                "You can apply for the program using the sign-up button on this page. During your sign-up, answer all the questions asked. Remember the login credentials you provide during sign-up, as you cannot change the email ID.",

        },
        {
            title: "3. How are the top SAs decided? ",

            content: `The top SAs will be determined based on a points system designed to fairly evaluate the performance of SAs. 
`,
        },
        {
            title: "4. What is the eligibility/criteria to become a SA? ",

            content: "if you are a student pursuing either B.Tech or B.E. and have a valid college ID, you can apply for this program.",
            
        },
        {
            title: "5. What is the eligibility/criteria to become a SA? ",

            content: "if you are a student pursuing either B.Tech or B.E. and have a valid college ID, you can apply for this program.",
            
        },
        {
            title: "6. How much time or effort on my part would be required? ",


            content: "A maximum of 2 hours per week would be requiredfrom a Student Ambassador.",
 
            
        },
        {
            title: "7. What is Milans?",

            content: "Milans is a mini Saarang event conducted for publicity. SAs are responsible for coordinating and conducting Milans in their respective colleges.",
        },
        {
            title: "8.What benefits do I get as a Student Ambassador?",


            content: " As a Student Ambassador, you will earn coupons and vouchers upon completing tasks, receive premium Saarang merchandise and goodies, have a chance to secure internship certificates, and participate in Saarang. You will also develop skills in team management, event organization, public speaking, social media, content writing and promotion, and other technical skills.",

        },
        {
            title: "9. Can I participate in the program if I am from a non-IIT Madras institution? ",


            content: "Yes, the Student Ambassador program is open to students from colleges across India..",

        },
        {
            title: "10. What should I do if I face challenges in completing a task? ",


            content: "If you encounter any challenges while completing a task, report them immediately to the Saarang team through the designated communication channels. Describe the issue in detail so that we can provide the necessary support.",

        },
    ],
};
const styles = {
    bgColor:  '#000319',
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: '#9CA3AF',
    arrowColor: "white",
    titleTextSize:'50px',
    rowTitleTextSize:'',
    rowContentTextSize:'large',
    rowTitlePaddingTop:'20px'
    
   

};
// window.addEventListener('resize', () => {
//     styles.rowTitleTextSize = window.innerWidth <= 768 ? '18px' : '30px';
//   });

const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true
};
const FAQ:React.FC=()=>{
    const { device } = useSelector((state: RootState) => state.windowSize);
    return(
        <div className="font-sans text-start tracking-wide"> <Faq
        data={data}
        styles={styles}
        config={config}
    /></div>
    )}
    export default FAQ;