import React, { useEffect, useState } from "react";

interface PopupProps {
  message: string;
  duration?: number;
}

const Popup: React.FC<PopupProps> = ({ message, duration = 3000 }) => {
  const [popup, setpopup] = useState<boolean>(true);
  useEffect(() => {
    const timer = setTimeout(() => {}, duration);

    return () => clearTimeout(timer); // Cleanup the timer
  }, [duration]);

  return (
    <div className={popup?"popup":"popup hide"}>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-[rgb(73,73,185)] text-white p-4 rounded-lg shadow-lg max-w-xs w-full">
          <h2 className="text-base font-bold mb-2">{message}</h2>
        </div>
      </div>
    </div>
  );
};

export default Popup;
