import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
 //  uri: "http://localhost:7000/graphql",
     uri: "https://api.saarang.org",
    cache: new InMemoryCache(),
    credentials: "include",
});

export default client;
