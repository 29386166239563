import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type Accomodation = {
  __typename?: 'Accomodation';
  Departure: Scalars['DateTimeISO']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  arrival: Scalars['DateTimeISO']['output'];
  college_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  leader_name: Scalars['String']['output'];
  mobile_no?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  num_females: Scalars['Float']['output'];
  num_males: Scalars['Float']['output'];
  num_tickets_booked: Scalars['Float']['output'];
  razorpay_order_id?: Maybe<Scalars['String']['output']>;
  razorpay_payment_id?: Maybe<Scalars['String']['output']>;
  user: Users;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  check_forgot: Scalars['Boolean']['output'];
  check_reg: Users;
  commonRegister: Users;
  login: Users;
  logout: Scalars['Float']['output'];
  register: Users;
  registerSa: Users;
};


export type MutationCheck_ForgotArgs = {
  email: Scalars['String']['input'];
  pass: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationCheck_RegArgs = {
  email_base64: Scalars['String']['input'];
  login_hash: Scalars['String']['input'];
};


export type MutationCommonRegisterArgs = {
  college: Scalars['String']['input'];
  email_primary: Scalars['String']['input'];
  email_secondary?: InputMaybe<Scalars['String']['input']>;
  isIITM: Scalars['String']['input'];
  mobile_number_primary: Scalars['String']['input'];
  mobile_number_secondary?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  portal: Scalars['String']['input'];
  positions?: Scalars['String']['input'];
  region: Scalars['String']['input'];
  state: Scalars['String']['input'];
  username: Scalars['String']['input'];
  year_of_study: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  DOB?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email_primary: Scalars['String']['input'];
  email_secondary?: InputMaybe<Scalars['String']['input']>;
  mobile_number_primary?: InputMaybe<Scalars['String']['input']>;
  mobile_number_secondary?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  positions?: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRegisterSaArgs = {
  sa_answer: Scalars['String']['input'];
  sa_answer2: Scalars['String']['input'];
  sa_answer3: Scalars['String']['input'];
  sa_referral?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  dummy?: Maybe<Scalars['String']['output']>;
  forgotPassword: Scalars['Boolean']['output'];
  getAllUsers: Array<Users>;
  getMe: Users;
  getUsersByPosition: Array<Users>;
};


export type QueryForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetUsersByPositionArgs = {
  positions: Scalars['String']['input'];
};

export type Submission = {
  __typename?: 'Submission';
  created_at: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  points_awarded: Scalars['Float']['output'];
  task: Task;
  task_proofs: Scalars['String']['output'];
  updated_at: Scalars['DateTimeISO']['output'];
  user: Users;
  verification_status: Scalars['Boolean']['output'];
};

export type Task = {
  __typename?: 'Task';
  created_at: Scalars['DateTimeISO']['output'];
  deadline: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  points: Scalars['Float']['output'];
  submission?: Maybe<Array<Submission>>;
  task_desp: Scalars['String']['output'];
  task_image: Scalars['String']['output'];
  task_name: Scalars['String']['output'];
  updated_at: Scalars['DateTimeISO']['output'];
};

export type Users = {
  __typename?: 'users';
  DOB?: Maybe<Scalars['DateTimeISO']['output']>;
  accomodation?: Maybe<Accomodation>;
  college?: Maybe<Scalars['String']['output']>;
  college_email_id?: Maybe<Scalars['String']['output']>;
  college_location?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTimeISO']['output']>;
  email_primary: Scalars['String']['output'];
  email_secondary?: Maybe<Scalars['String']['output']>;
  isIITM?: Maybe<Scalars['String']['output']>;
  mobile_number_primary?: Maybe<Scalars['String']['output']>;
  mobile_number_secondary?: Maybe<Scalars['String']['output']>;
  points_scored?: Maybe<Scalars['Float']['output']>;
  positions: Scalars['String']['output'];
  profile_picture?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  sa_answer?: Maybe<Scalars['String']['output']>;
  sa_answer2?: Maybe<Scalars['String']['output']>;
  sa_answer3?: Maybe<Scalars['String']['output']>;
  sa_referral?: Maybe<Scalars['String']['output']>;
  saarang_id: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  submission?: Maybe<Submission>;
  updated_at?: Maybe<Scalars['DateTimeISO']['output']>;
  user_id: Scalars['String']['output'];
  username: Scalars['String']['output'];
  verified?: Maybe<Scalars['Boolean']['output']>;
  year_of_study?: Maybe<Scalars['String']['output']>;
};

export type CommonRegisterMutationVariables = Exact<{
  emailPrimary: Scalars['String']['input'];
  username: Scalars['String']['input'];
  mobileNumberPrimary: Scalars['String']['input'];
  password: Scalars['String']['input'];
  portal: Scalars['String']['input'];
  college: Scalars['String']['input'];
  yearOfStudy: Scalars['String']['input'];
  region: Scalars['String']['input'];
  state: Scalars['String']['input'];
  isIitm: Scalars['String']['input'];
  positions: Scalars['String']['input'];
}>;


export type CommonRegisterMutation = { __typename?: 'Mutation', commonRegister: { __typename?: 'users', user_id: string, positions: string, username: string } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'users', user_id: string, positions: string, username: string } };

export type RegisterMutationVariables = Exact<{
  emailPrimary: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
  positions: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['DateTimeISO']['input']>;
  mobileNumberSecondary?: InputMaybe<Scalars['String']['input']>;
  mobileNumberPrimary?: InputMaybe<Scalars['String']['input']>;
  emailSecondary?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'users', user_id: string } };

export type RegisterSaMutationVariables = Exact<{
  saAnswer: Scalars['String']['input'];
  saAnswer2: Scalars['String']['input'];
  saAnswer3: Scalars['String']['input'];
  saReferral?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterSaMutation = { __typename?: 'Mutation', registerSa: { __typename?: 'users', positions: string, user_id: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: number };

export type Check_ForgotMutationVariables = Exact<{
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
  pass: Scalars['String']['input'];
}>;


export type Check_ForgotMutation = { __typename?: 'Mutation', check_forgot: boolean };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', getMe: { __typename?: 'users', user_id: string, saarang_id: string, positions: string, username: string, sa_answer?: string | null, sa_referral?: string | null } };

export type ForgotPasswordQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgotPasswordQuery = { __typename?: 'Query', forgotPassword: boolean };


export const CommonRegisterDocument = gql`
    mutation CommonRegister($emailPrimary: String!, $username: String!, $mobileNumberPrimary: String!, $password: String!, $portal: String!, $college: String!, $yearOfStudy: String!, $region: String!, $state: String!, $isIitm: String!, $positions: String!) {
  commonRegister(
    email_primary: $emailPrimary
    username: $username
    mobile_number_primary: $mobileNumberPrimary
    password: $password
    portal: $portal
    college: $college
    year_of_study: $yearOfStudy
    region: $region
    state: $state
    isIITM: $isIitm
    positions: $positions
  ) {
    user_id
    positions
    username
  }
}
    `;
export type CommonRegisterMutationFn = Apollo.MutationFunction<CommonRegisterMutation, CommonRegisterMutationVariables>;

/**
 * __useCommonRegisterMutation__
 *
 * To run a mutation, you first call `useCommonRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommonRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commonRegisterMutation, { data, loading, error }] = useCommonRegisterMutation({
 *   variables: {
 *      emailPrimary: // value for 'emailPrimary'
 *      username: // value for 'username'
 *      mobileNumberPrimary: // value for 'mobileNumberPrimary'
 *      password: // value for 'password'
 *      portal: // value for 'portal'
 *      college: // value for 'college'
 *      yearOfStudy: // value for 'yearOfStudy'
 *      region: // value for 'region'
 *      state: // value for 'state'
 *      isIitm: // value for 'isIitm'
 *      positions: // value for 'positions'
 *   },
 * });
 */
export function useCommonRegisterMutation(baseOptions?: Apollo.MutationHookOptions<CommonRegisterMutation, CommonRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommonRegisterMutation, CommonRegisterMutationVariables>(CommonRegisterDocument, options);
      }
export type CommonRegisterMutationHookResult = ReturnType<typeof useCommonRegisterMutation>;
export type CommonRegisterMutationResult = Apollo.MutationResult<CommonRegisterMutation>;
export type CommonRegisterMutationOptions = Apollo.BaseMutationOptions<CommonRegisterMutation, CommonRegisterMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user_id
    positions
    username
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($emailPrimary: String!, $username: String!, $password: String!, $positions: String!, $dob: DateTimeISO, $mobileNumberSecondary: String, $mobileNumberPrimary: String, $emailSecondary: String) {
  register(
    email_primary: $emailPrimary
    username: $username
    password: $password
    positions: $positions
    DOB: $dob
    mobile_number_secondary: $mobileNumberSecondary
    mobile_number_primary: $mobileNumberPrimary
    email_secondary: $emailSecondary
  ) {
    user_id
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      emailPrimary: // value for 'emailPrimary'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      positions: // value for 'positions'
 *      dob: // value for 'dob'
 *      mobileNumberSecondary: // value for 'mobileNumberSecondary'
 *      mobileNumberPrimary: // value for 'mobileNumberPrimary'
 *      emailSecondary: // value for 'emailSecondary'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RegisterSaDocument = gql`
    mutation RegisterSa($saAnswer: String!, $saAnswer2: String!, $saAnswer3: String!, $saReferral: String) {
  registerSa(
    sa_answer: $saAnswer
    sa_answer2: $saAnswer2
    sa_answer3: $saAnswer3
    sa_referral: $saReferral
  ) {
    positions
    user_id
  }
}
    `;
export type RegisterSaMutationFn = Apollo.MutationFunction<RegisterSaMutation, RegisterSaMutationVariables>;

/**
 * __useRegisterSaMutation__
 *
 * To run a mutation, you first call `useRegisterSaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterSaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerSaMutation, { data, loading, error }] = useRegisterSaMutation({
 *   variables: {
 *      saAnswer: // value for 'saAnswer'
 *      saAnswer2: // value for 'saAnswer2'
 *      saAnswer3: // value for 'saAnswer3'
 *      saReferral: // value for 'saReferral'
 *   },
 * });
 */
export function useRegisterSaMutation(baseOptions?: Apollo.MutationHookOptions<RegisterSaMutation, RegisterSaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterSaMutation, RegisterSaMutationVariables>(RegisterSaDocument, options);
      }
export type RegisterSaMutationHookResult = ReturnType<typeof useRegisterSaMutation>;
export type RegisterSaMutationResult = Apollo.MutationResult<RegisterSaMutation>;
export type RegisterSaMutationOptions = Apollo.BaseMutationOptions<RegisterSaMutation, RegisterSaMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const Check_ForgotDocument = gql`
    mutation Check_forgot($email: String!, $token: String!, $pass: String!) {
  check_forgot(email: $email, token: $token, pass: $pass)
}
    `;
export type Check_ForgotMutationFn = Apollo.MutationFunction<Check_ForgotMutation, Check_ForgotMutationVariables>;

/**
 * __useCheck_ForgotMutation__
 *
 * To run a mutation, you first call `useCheck_ForgotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheck_ForgotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkForgotMutation, { data, loading, error }] = useCheck_ForgotMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *      pass: // value for 'pass'
 *   },
 * });
 */
export function useCheck_ForgotMutation(baseOptions?: Apollo.MutationHookOptions<Check_ForgotMutation, Check_ForgotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Check_ForgotMutation, Check_ForgotMutationVariables>(Check_ForgotDocument, options);
      }
export type Check_ForgotMutationHookResult = ReturnType<typeof useCheck_ForgotMutation>;
export type Check_ForgotMutationResult = Apollo.MutationResult<Check_ForgotMutation>;
export type Check_ForgotMutationOptions = Apollo.BaseMutationOptions<Check_ForgotMutation, Check_ForgotMutationVariables>;
export const GetMeDocument = gql`
    query GetMe {
  getMe {
    user_id
    saarang_id
    positions
    username
    sa_answer
    sa_referral
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export function useGetMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<typeof useGetMeSuspenseQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const ForgotPasswordDocument = gql`
    query forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;

/**
 * __useForgotPasswordQuery__
 *
 * To run a query within a React component, call `useForgotPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForgotPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordQuery(baseOptions: Apollo.QueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables> & ({ variables: ForgotPasswordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
      }
export function useForgotPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
        }
export function useForgotPasswordSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
        }
export type ForgotPasswordQueryHookResult = ReturnType<typeof useForgotPasswordQuery>;
export type ForgotPasswordLazyQueryHookResult = ReturnType<typeof useForgotPasswordLazyQuery>;
export type ForgotPasswordSuspenseQueryHookResult = ReturnType<typeof useForgotPasswordSuspenseQuery>;
export type ForgotPasswordQueryResult = Apollo.QueryResult<ForgotPasswordQuery, ForgotPasswordQueryVariables>;