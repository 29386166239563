import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForgotPasswordLazyQuery } from "../../../generated/graphql";
import { RootState } from "../../../redux/device/reducers";
import SlidingImage1 from "../../../Images/Sliding-Image-1.jpg";
import SlidingImage2 from "../../../Images/Sliding-Image-2.jpg";
import SlidingImage3 from "../../../Images/Sliding-Image-3.jpg";
import SlidingImage4 from "../../../Images/Sliding-Image-4.jpg";
import SlidingImage5 from "../../../Images/Sliding-Image-5.jpg";
import SlidingImage6 from "../../../Images/Sliding-Image-6.jpg";
import SlidingImage7 from "../../../Images/Sliding-Image-7.jpg";
import "./forgot.scss";
import PortalContext from "../../../contexts/portalContext";
import useWindowSize from "../../../redux/device/useWindowSize";
import Popup from "../../../ui-elements/popup/popup";

const ForgotPassword: React.FC = () => {
  useWindowSize();
  const portalContext = useContext(PortalContext);
  const { device } = useSelector((state: RootState) => state.windowSize);
  const [email, setEmail] = useState<string>("");

  const submitHandler = (e:any) => {
    e.preventDefault();
    // console.log("forgot called")
    forgotPassword({
      variables: {
        email: email,
      },
    });
  };
  const Images = [
    SlidingImage1,
    SlidingImage2,
    SlidingImage3,
    SlidingImage4,
    SlidingImage5,
    SlidingImage6,
    SlidingImage7,
  ];
  const [currentIndex, setCurrentIndex] = useState<number>(
    Math.floor(Math.random() * 7)
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  // Lazy load the query
  const [forgotPassword, { data, loading, error }] = useForgotPasswordLazyQuery(
    {
      fetchPolicy: "network-only",
    }
  );

  return (
    <div className="signup_box">
             {error && <Popup message=  {error.message.split(":")[1]}/>} 
             {loading && <Popup message="loading.."/>}
             {data && <Popup message=" Check you mail for reset link"/>}

      <div className={`registration-form-and-image ${device}`}>
        <div className={`sliding-image login ${device}`}>
          {Images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className={`image-slider__image ${
                index === currentIndex ? "active" : ""
              }`}
            />
          ))}
        </div>

        <div className={`registration-form active ${device}`}>
          <div className={`forgot-box ${device}`}>
            <div className={`heading text-white ${device} `}>
              Change Password
            </div>
            <div className="width100 ">
              <br />
              <input
                type="email"
                className={`form-styling ${device}`}
                id="email"
                placeholder="Enter Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <br />
            <div className="width100">
              <button type="button" className={`button-style ${device}`} onClick={submitHandler} >
                Get reset link
              </button>
            </div>
           
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
