import React, { useContext, useEffect } from "react";
import {
  useGetMeQuery,
  useLogoutMutation,
  useRegisterSaMutation,
} from "../../../generated/graphql";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/authContext";

const Header = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const [logoutMutation, { data, loading, error }] = useLogoutMutation();
  const handleLogout = async () => {
    try {
      await logoutMutation(); // Call the logout mutation
      navigate("/auth"); // Redirect to login page after logout
    } catch (err) {
      console.error("Logout failed:", err); // Handle any errors
    }
  };
  useEffect(() => {
    // console.log("L", data);
    if (data) {
      if (data.logout === 1) {
        if (authContext) {
          authContext.signOut();
        }
      }
    }
  }, [data]);
  if (!authContext) {
    return <div>Error: Error in logging in.</div>;
  }
  const { state } = authContext;

  return (
    <div className="bg-[#0003197a] w-full h-auto sticky top-0 z-40 backdrop-blur-sm">
      <div className="lg:px-8 px-2 w-full justify-between flex py-2">
        <div>
        <img src="favicon.ico" alt=""className='lg:w-24 lg:h-24 w-16 h-16'/>
        </div>
        <div className="flex items-center justify-center text-xl text-white">
          <h2 className="lg:space-x-2">
            <span className="font-semibold justify-center lg:text-4xl tracking-wide gradient-text">
              Saarang'25
            </span>
            <span className="text-sm lg:text-3xl tracking-wide">
              | SA portal
            </span>
          </h2>
        </div>
        <div className="flex justify-center items-center">
          {state.user ? (
            <button
              className="text-white font-Medium text-sm lg:text-xl tracking-wide border-2 border-white lg:px-6 px-2 py-1 rounded-md"
              onClick={handleLogout}
            >
              Logout
            </button>
          ) : (
            <button
              className="text-white font-Medium text-sm lg:text-xl tracking-wide border-2 border-white lg:px-6 px-2 py-1 rounded-md"
              onClick={() => navigate("/auth")}
            >
              Login
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
